
let env = {};

env.baseURL = 'https://api.samudiadema.it4d.com.br/api/v1/';
env.normalURL = 'https://api.samudiadema.it4d.com.br/';
// env.mode = 'homolog';
// env.mode = 'production';
env.mode = 'development';
env.wsHost = 'ws.samudiadema.it4d.com.br';
//env.wsPort = '6003';
env.wsKey = 'Adesp@c';
env.wsCluster = 'mt1';


export default env
